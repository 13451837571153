import { CheckIcon } from '@heroicons/react/24/solid';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab } from '@mui/material';
import {
  ADMIN_STATUS,
  CreativeRequest,
  CREATIVE_STATUS,
} from 'API';
import { Storage } from 'aws-amplify';
import SuccessModal from 'components/authentication/modal';
import { IconLoader } from 'components/loader';
import ExportPDF from 'components/pdfSubmission/pdf';
import { GetVideoPreviwUrl } from 'hooks/query/useAdminActions';
import { TWO_DAYS_IN_SECONDS } from 'hooks/utils';
import _ from 'lodash';
import { AdminCreativeRequestsTabs } from 'pages/adminCreativeUsers/constants';
import PdfViewer from 'pages/previewWindow/pdfViewer';
import { FC, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ViewRequestProps, withRequestView } from 'state/requests';
import { BrandRoutes, UnknownType } from 'utils';
import '../campaignSlider/campaignSlider.css';
import EarningsTab from './components/EarningsTab/EarningsTab';
import { CreativeDetails } from './CreativeDetails';

// REFACTOR_ME:

interface Props {
  videoUrl?: string;
  reqLoading?: boolean;
  onClose: () => void;
  request: CreativeRequest | null;
  createAdPayload: UnknownType;
  updateCreativeRequestStatus?: (
    newStatus: string,
    comment?: string,
    sendEmail?: boolean,
    previousStatus?: string
  ) => void;
  onSuccess?: () => Promise<void>;
  type?: string;
}

export const AdminApprovalContent: FC<Props & ViewRequestProps> = ({
  videoUrl,
  onClose,
  type,
  updateCreativeRequestStatus,
  request,
  reqLoading,
  getVideoLink,
  approveRequest,
  rejectRequest,
  createAdPayload,
  createAdResponse,
  loading,
  isSuccess,
  errorMsg,
  onSuccess,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [awsURL, setAwsURL] = useState<string>('');
  const [creativeUrl, setCreativeUrl] = useState<string>('');
  const [adName, setAdName] = useState('');
  const [adError, setAdError] = useState('');
  const [rejectComment, setRejectComment] = useState('');
  const [rejectError, setRejectError] = useState(false);
  const [actType, setActType] = useState('');
  const [isCopy, setIsCopy] = useState(false);
  const [previewPdf, setPreviewPdf] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const sendRejectionEmail = useRef(true);
  const [tab, setTab] = useState<AdminCreativeRequestsTabs>(
    AdminCreativeRequestsTabs.General
  );

  const [selectedVerticals, setSelectedVerticals] = useState<Set<string>>(
    new Set()
  );


  const {
    getPreviewUrl,
    data: previewData,
    loading: previewDataLoading,
  } = GetVideoPreviwUrl();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const onOkay = async () => {
    if (!adName) {
      setAdError('Ad name is required');
    } else if (isConfirmationOpen) {
      setAdError('');
      approveRequest(createAdPayload, adName, request);
    }
  };
  const getEmbeddedUrl = (e: string): string => {
    try {
      const { hostname, pathname } = new URL(e);
      if (hostname.includes('tiktok.com')) {
        const videoId = pathname.split('/').at(-1);
        const isValidId = /^\d+$/.test(videoId || '');
        if (videoId?.length && isValidId)
          return `https://www.tiktok.com/embed/v2/${videoId}`;
      }
      return e;
    } catch (err) {
      return e;
    }
  };

  const onReject = () => {
    setActType('reject');
    if (!rejectComment) setRejectError(true);
    if (type === 'Admin' && rejectComment && updateCreativeRequestStatus) {
      updateCreativeRequestStatus(
        ADMIN_STATUS.Rejected,
        rejectComment,
        sendRejectionEmail.current
      );
    }

    if (type !== 'Admin' && !isConfirmationOpen && rejectComment && request) {
      rejectRequest(rejectComment, request, sendRejectionEmail.current);
    }
  };

  const onRevision = () => {
    setActType('revision');
    if (!rejectComment) setRejectError(true);
    if (type === 'Admin' && rejectComment && updateCreativeRequestStatus) {
      updateCreativeRequestStatus(
        ADMIN_STATUS.Revision,
        rejectComment,
        sendRejectionEmail.current
      );
    }
  };

  const onApprove = () => {
    if (!isConfirmationOpen) setIsConfirmationOpen(true);
  };

  function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }

  const getPDf = (url: string) => {
    setPreviewPdf(url);
  };

  const getPdfUrlFromBucket = async () => {
    const url = await Storage.get(`SubmissionPdf/${request?.id}`);
    fetch(url).then((res) => {
      if (res.status === 200) setPreviewPdf(url);
      if (res.status === 404) setPreviewPdf('');
    });
  };

  useEffect(() => {
    if (request?.tiktokVideoCode) {
      getVideoLink(request.tiktokVideoCode);
    }

    const creativeKey =
      request?.tiktokCreativeUrl?.replace('public/', '') || '';
    Storage.get(creativeKey).then((data) => {
      setCreativeUrl(data);
    });

    const key = (
      request?.creativePreviewUrl || request?.tiktokCreativeUrl
    )?.replace('public/', '');

    Storage.get(`${key}`, {
      expires: TWO_DAYS_IN_SECONDS,
    })
      .then((data) => {
        setAwsURL(data);
      })
      .catch((err) =>
        console.log(`Failed to load ${request?.tiktokCreativeUrl}:`, err)
      );
  }, [request]);

  useEffect(() => {
    if (!previewData && request) {
      getPreviewUrl({ variables: { videoPath: request.tiktokCreativeUrl } });
    }
  }, [previewData, request]);

  useEffect(() => {
    if (!loading && isSuccess) onClose();
  }, [loading, isSuccess, onClose]);

  useEffect(() => {
    if (createAdResponse && !showSuccessModal) {
      setIsConfirmationOpen(false);
      setShowSuccessModal(true);
    }
  }, [createAdResponse, showSuccessModal]);

  useEffect(() => {
    setSelectedVerticals(new Set(request?.creatorProfile?.hashtags || []));
    getPdfUrlFromBucket();
  }, []);

  const onChangeTab = (
    event: React.SyntheticEvent,
    newTab: AdminCreativeRequestsTabs
  ) => {
    setTab(newTab);
  };

  const [isNotificationEnabled, setIsNotificationEnabled] = useState(
    sendRejectionEmail.current
  );

  const handleToggleNotification = () => {
    const newValue = !isNotificationEnabled;
    setIsNotificationEnabled(newValue); // Update state to trigger re-render
    sendRejectionEmail.current = newValue; // Update the ref value
    console.log("Send Notification Toggled:", newValue); // Log for debugging
  };

  const generatePreviewUrl = (request, awsURL, videoUrl) => {

    const baseUrl = process.env.REACT_APP_FRONTEND_BASE_URL;
    const { uniqueId, brief, creativePreviewUrl, tiktokCreativeUrl } =
      request || {};
    const briefName = brief?.BriefName;
    const brandImage = brief?.brandProfile?.userProfile?.avatar;

    const splitAndJoin = (url) => url.split('/').join('!');

    const previewPath = awsURL
      ? splitAndJoin(creativePreviewUrl || tiktokCreativeUrl || '')
      : videoUrl
        ? splitAndJoin(getEmbeddedUrl(videoUrl))
        : '';

    return `${baseUrl}preview/${previewPath}?id=${uniqueId}&briefName=${briefName}&brandImage=${brandImage}`;
  };

  return (
    <>
      <div className="border border-[#f5f1e8] rounded-[16px] w-full p-[20px]">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              TabIndicatorProps={{
                className: 'bg-orange-main text-orange-main',
              }}
              onChange={onChangeTab}
            >
              <Tab
                disableRipple={true}
                className="text-[black] head-text text-[500]"
                value={AdminCreativeRequestsTabs.General}
                label={AdminCreativeRequestsTabs.General}
              ></Tab>
              <Tab
                disableRipple={true}
                className="text-[black] head-text text-[500]"
                value={AdminCreativeRequestsTabs.Details}
                label={AdminCreativeRequestsTabs.Details}
              ></Tab>
              <Tab
                disableRipple={true}
                className="text-[black] head-text text-[500]"
                value={AdminCreativeRequestsTabs.Earnings}
                label={AdminCreativeRequestsTabs.Earnings}
              ></Tab>
            </TabList>
          </Box>

          <TabPanel value={AdminCreativeRequestsTabs.General}>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
              {/* Left panel */}
              <div className="col-span-1 flex justify-center lg:justify-start">
                <div
                  className="w-full max-w-sm lg:max-w-md overflow-hidden">
                  {awsURL ? (
                    <video
                      controls
                      className="w-full h-[300px] lg:h-[700px] object-cover"
                      autoPlay
                      muted
                      style={{
                        objectFit: "cover", // Ensures the video fills the container without black bars
                      }}
                    >
                      <source src={awsURL} />
                    </video>
                  ) : (
                    <p className="text-gray-500 text-center">No Video Exists</p>
                  )}
                </div>
              </div>

              {/* Right panel */}
              <div className="col-span-3 flex flex-col gap-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* Brand View Comments Section */}
                  <div>
                    <h6
                      className="mb-4"
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        textTransform: "uppercase",
                      }}
                    >
                      BRAND VIEW COMMENTS
                    </h6>
                    <ul
                      className="w-full border border-gray-300 rounded-md p-3 text-sm"
                      style={{
                        listStyleType: "disc",
                        paddingLeft: "20px",
                        height: "200px",
                        overflowY: "auto",
                      }}
                    >
                      {Array.isArray(request?.brandComment) &&
                        request.brandComment.length > 0 ? (
                        request.brandComment.map((comment, index) => (
                          <li
                            key={index}
                            style={{ marginBottom: "10px", marginLeft: "15px" }}
                          >
                            <p>{comment}</p>
                          </li>
                        ))
                      ) : (
                        <p className="text-gray-500 italic">No comments available.</p>
                      )}
                    </ul>
                  </div>

                  {/* Approve/Reject Section */}
                  <div>
                    <h6
                      className="mb-4"
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        textTransform: "uppercase",
                      }}
                    >
                      APPROVE / REJECT
                    </h6>
                    <div>
                      {/* Text Input */}
                      <div
                        contentEditable
                        className={`text-sm mb-4 border rounded-md p-3 w-full ${rejectError ? "border-red-500" : "border-gray-300"
                          }`}
                        style={{
                          height: "200px",
                          backgroundColor: "#FFFFFF",
                          fontFamily: "Inter, sans-serif",
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "#333333",
                          overflowY: "auto",
                        }}
                        onInput={(e) => {
                          setRejectComment(e.currentTarget.textContent || "");
                          if (rejectError) setRejectError(false);
                        }}
                      ></div>
                      {rejectError && (
                        <p className="text-red-500 text-sm mt-1">Please enter a comment.</p>
                      )}

                      {/* Buttons */}
                      <div className="flex flex-col lg:flex-row justify-between mt-4 w-full gap-4">
                        {/* Reject Button */}
                        <button
                          onClick={() => {
                            if (!rejectComment.trim()) {
                              setRejectError(true);
                              return;
                            }
                            onReject();
                          }}
                          disabled={
                            reqLoading || request?.adminApproval === ADMIN_STATUS.Rejected
                          }
                          className={`px-8 py-3 rounded-md shadow-lg transition-transform hover:scale-105 ${reqLoading || request?.adminApproval === ADMIN_STATUS.Rejected
                            ? "bg-gray-300 text-white cursor-not-allowed"
                            : "bg-[#E11D48] text-white"
                            }`}
                          style={{
                            fontFamily: "Oswald, sans-serif",
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            minWidth: "150px",
                          }}
                        >
                          {reqLoading && actType === "reject" ? (
                            <div className="loader-content h-3">
                              <IconLoader />
                            </div>
                          ) : (
                            "Reject"
                          )}
                        </button>

                        {/* Revision Button */}
                        <button
                          onClick={() => {
                            if (!rejectComment.trim()) {
                              setRejectError(true);
                              return;
                            }
                            onRevision();
                          }}
                          disabled={reqLoading}
                          className={`px-8 py-3 rounded-md shadow-lg transition-transform hover:scale-105 ${reqLoading
                            ? "bg-gray-300 text-white cursor-not-allowed"
                            : "bg-[#F97316] text-white"
                            }`}
                          style={{
                            fontFamily: "Oswald, sans-serif",
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            minWidth: "150px",
                          }}
                        >
                          {reqLoading && actType === "revision" ? (
                            <div className="loader-content h-3">
                              <IconLoader />
                            </div>
                          ) : (
                            "Revision"
                          )}
                        </button>

                        {/* Approve Button */}
                        <button
                          onClick={() => {
                            if (type === "Admin" && updateCreativeRequestStatus) {
                              updateCreativeRequestStatus(
                                ADMIN_STATUS.Approved,
                                "",
                                true,
                                ADMIN_STATUS.Revision
                              );
                            } else {
                              onApprove();
                            }
                          }}
                          disabled={
                            reqLoading || request?.adminApproval === ADMIN_STATUS.Approved
                          }
                          className={`px-8 py-3 rounded-md shadow-lg transition-transform hover:scale-105 ${reqLoading || request?.adminApproval === ADMIN_STATUS.Approved
                            ? "bg-gray-300 text-white cursor-not-allowed"
                            : "bg-[#000000] text-white"
                            }`}
                          style={{
                            fontFamily: "Oswald, sans-serif",
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            minWidth: "150px",
                          }}
                        >
                          {reqLoading &&
                            actType !== "revision" &&
                            actType !== "reject" ? (
                            <div className="loader-content h-3">
                              <IconLoader />
                            </div>
                          ) : (
                            "Approve"
                          )}
                        </button>
                      </div>


                      {/* Notification Checkbox */}
                      <div className="flex items-center gap-2 mt-4">
                        <label
                          htmlFor="sendNotification"
                          className="text-sm"
                          style={{
                            fontFamily: "Inter, sans-serif",
                            fontSize: "14px",
                            color: "#6B7280",
                            cursor: "pointer",
                          }}
                        >
                          Send notification
                        </label>
                        <input
                          type="checkbox"
                          id="sendNotification"
                          className="cursor-pointer"
                          checked={isNotificationEnabled}
                          onChange={handleToggleNotification}
                        />
                      </div>
                    </div>
                  </div>

                </div>

                {/* Creator Tags */}
                <div className="mt-5">
                  <h6 className="text-[#0E0D0D] font-bold sm:text-[16px] text-[14px] mb-3 uppercase text-left">
                    Creator Hashtags
                  </h6>
                  <div
                    className={`relative brand-dashboard__profile-group ${Array.from(selectedVerticals || []).length > 0
                      ? 'flex flex-wrap justify-center gap-2'
                      : 'text-left'
                      }`}
                    style={{
                      maxHeight: '300px',
                      overflowY: 'auto',
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none',
                    }}
                  >
                    {Array.from(selectedVerticals || []).length > 0 ? (
                      Array.from(selectedVerticals).map((vertical, index) => (
                        <div
                          key={index}
                          className={`border bg-[#202020] text-white rounded-[80px] cursor-pointer flex items-center justify-center px-4 py-2`}
                        >
                          <span className="text-sm text-center">{vertical}</span>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-500 italic text-sm">
                        The user has no tags.
                      </p>
                    )}
                  </div>
                </div>



                {/* For Admin For Creator Have reviewed */}
                {request?.adminApproval && type === 'Admin' && (
                  <div className="mt-4">
                    {capitalizeFirstLetter(request?.adminApproval) === CREATIVE_STATUS.Approved ? (
                      <span className="text-success flex items-center font-semibold">
                        You have already approved this creative request
                        <CheckIcon className="w-6 ml-2" />
                      </span>
                    ) : (
                      <div>
                        <span
                          className={`flex items-center font-semibold text-[15px] ${capitalizeFirstLetter(request?.adminApproval) === 'Revision'
                            ? 'text-black'
                            : 'text-danger'
                            }`}
                        >
                          {capitalizeFirstLetter(request?.adminApproval) === 'Revision'
                            ? 'You have sent this creative request for revision'
                            : 'You have already rejected this creative request'}
                        </span>
                        <div className="bg-white rounded-lg p-3 h-[116px] overflow-y-auto mt-3">
                          {(request?.adminComment?.length ?? 0) > 0 && (
                            <div>
                              <h6 className="font-bold mb-2">From Admin:</h6>
                              {request?.adminComment?.map((comment, index) => (
                                <div key={index} className="flex pb-1">
                                  - <span className="first-letter:capitalize pl-1">{comment}</span>
                                </div>
                              ))}
                            </div>
                          )}
                          {capitalizeFirstLetter(request?.adminApproval) === 'Revision' &&
                            (request?.creatorComment?.length ?? 0) > 0 && (
                              <div className="mt-2">
                                <h6 className="font-bold mb-2">From Creator:</h6>
                                {request?.creatorComment?.map((comment, index) => (
                                  <div key={index} className="flex pb-1">
                                    - <span className="first-letter:capitalize pl-1">{comment}</span>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}


                {/* Activation Description */}
                <div>
                  <h6 className="font-semibold text-lg mb-2">Activation Description</h6>
                  <p className="border border-gray-200 p-4 rounded-md">
                    {request?.briefDescription || "No activation description available."}
                  </p>
                </div>

                {/* Download Buttons */}
                {!previewDataLoading && previewData !== undefined ? (
                  <div>
                    {type === 'Admin' && previewData ? (
                      <div className="flex flex-col sm:flex-row justify-between items-center mt-5 gap-4">
                        {/* Download Buttons */}
                        <div className="flex gap-2">
                          <a
                            href={awsURL}
                            download
                            className="creator-button flex items-center gap-2 px-4 py-2 text-sm font-medium"
                          >
                            <svg
                              className="fill-current w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                            </svg>
                            <span>Download Preview</span>
                          </a>
                          <a
                            href={creativeUrl}
                            download
                            className="creator-button flex items-center gap-2 px-4 py-2 text-sm font-medium"
                          >
                            <svg
                              className="fill-current w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                            </svg>
                            <span>Download Original</span>
                          </a>
                        </div>

                        {/* Copy Preview URL Button */}
                        <CopyToClipboard
                          onCopy={() => {
                            setIsCopy(true);
                            setTimeout(() => setIsCopy(false), 1000);
                          }}
                          text={generatePreviewUrl(request, awsURL, videoUrl)}
                        >
                          <button className="creator-button px-4 py-2 text-sm font-medium">
                            {isCopy ? 'Copied' : 'Copy Preview URL'}
                          </button>
                        </CopyToClipboard>

                        {/* PDF Actions */}
                        {previewPdf && (
                          <div className="flex items-center gap-2">
                            <a
                              href={previewPdf}
                              className="border border-gray-400 rounded-md p-1"
                              data-tooltip-id="tooltip-download-pdf"
                            >
                              <img
                                src="images/download-pdf.png"
                                alt="Download PDF"
                                className="w-6 h-6"
                              />
                            </a>
                            <button
                              className="border border-gray-400 rounded-md p-1"
                              onClick={() => setShowPdf(true)}
                              data-tooltip-id="tooltip-see-pdf"
                            >
                              <img
                                src="images/view-pdf.png"
                                alt="View PDF"
                                className="w-6 h-6"
                              />
                            </button>
                            <ReactTooltip
                              id="tooltip-see-pdf"
                              place="top"
                              content="See PDF"
                              className="text-xs"
                            />
                            <ReactTooltip
                              id="tooltip-download-pdf"
                              place="top"
                              content="Download PDF"
                              className="text-xs"
                            />
                          </div>
                        )}

                        {/* PDF Viewer */}
                        {showPdf && <PdfViewer fileUrl={previewPdf} onClose={() => setShowPdf(false)} />}

                        <ExportPDF request={request} getPDf={getPDf} />
                      </div>
                    ) : (
                      <div className="text-center mt-5">
                        <h6 className="font-bold">Preview URL is absent</h6>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="loader-content h-8 mt-5">
                    <IconLoader />
                  </div>
                )}

              </div>
            </div>
          </TabPanel>




          {/* Details and Earnings Tabs */}
          <TabPanel value={AdminCreativeRequestsTabs.Details}>
            <CreativeDetails onSuccess={onSuccess} creativeRequest={request} />
          </TabPanel>
          <TabPanel value={AdminCreativeRequestsTabs.Earnings}>
            <EarningsTab creativeRequestId={request?.id || ''} userProfileId={request?.creatorId || ''} />
          </TabPanel>
        </TabContext>
      </div>


      <SuccessModal
        isOpen={showSuccessModal}
        handleClose={() => (window.location.href = BrandRoutes.Creatives)}
        type="brand"
        content="Ad was successfully created"
      />

      <Modal
        title="Please confirm & specify ad name"
        isOpen={isConfirmationOpen}
        handleClose={() => setIsConfirmationOpen(false)}
      >
        <div className="brand-dashboard__profile-group mt-5">
          <div className="brand-dashboard__profile-label">Ad name</div>
          <input
            className="brand-dashboard__profile-input"
            value={adName}
            onChange={(e): void => setAdName(e.target.value)}
          />
        </div>
        <p className="w-100 mb-5 text-center">
          Clicking confirm below will add this creative to your campaign and
          will start spending.
        </p>
        {errorMsg || adError ? (
          <p
            className={`text-center text-danger mb-5 ${adError ? 'mt-2' : 'my-8'
              }`}
          >
            {errorMsg || adError}
          </p>
        ) : null}
        <div className="flex justify-center">
          <button
            className="creator-button w-[300px]"
            data-cy={'confirm'}
            onClick={onOkay}
            disabled={loading}
          >
            {loading && !createAdResponse ? <IconLoader /> : 'Confirm'}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default withRequestView(AdminApprovalContent);
