import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './Footer.css';
import edcInsta from '../../assets/svg/edc-insta.svg';
import edcLinkIn from '../../assets/svg/edc-linkin.svg';
import edcWhatsapp from '../../assets/svg/edc-whatsapp.svg';
import edcLogoWhite from '../../assets/svg/edc-logo-white.svg';
import edcTikTok from "../../assets/svg/edc-ticktok.svg";
import tiktokCreative from "../../assets/svg/edc-footer-tiktok-creative.svg";
import { WHATSAPP_DM_URL, WHATSAPP_COMMUNITY_URL } from "../../constants";

const BASE_URL = process.env.REACT_APP_URL || '';

const Footer: React.FC = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const currentYear = new Date().getFullYear();

  const isMobile: boolean = useMediaQuery({ query: '(max-width: 768px)' });

  const handleLoginClick = (): void => {
    window.location.href = 'https://app.edcsquared.io/login';
  };

  const togglePopup = (): void => {
    setShowPopup((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-left">
            <h2 className="footer-email">hello@edcsquared.io</h2>
            <div className="footer-social">
              <a href="https://www.instagram.com/edcsq/" target="_blank" rel="noopener noreferrer">
                <img src={edcInsta} alt="Instagram" className="social-icon" />
              </a>
              <a href="https://www.linkedin.com/company/edcsquared/" target="_blank" rel="noopener noreferrer">
                <img src={edcLinkIn} alt="LinkedIn" className="social-icon" />
              </a>
              <div className="popup-container" ref={popupRef}>
                <button onClick={togglePopup} className="social-icon-button">
                  <img src={edcWhatsapp} alt="WhatsApp" className="social-icon-whatsapp" />
                </button>
                {showPopup && (
                  <div className="popup">
                    <a href={WHATSAPP_DM_URL} target="_blank" rel="noopener noreferrer">Chat Directly</a>
                    <a href={WHATSAPP_COMMUNITY_URL} target="_blank" rel="noopener noreferrer">Join our Community</a>
                  </div>
                )}
              </div>
              <a href="https://www.tiktok.com/@edcsquared" target="_blank" rel="noopener noreferrer">
                <img src={edcTikTok} alt="TikTok" className="social-icon" />
              </a>
            </div>
          </div>
          <div className="footer-right">
            <img src={tiktokCreative} alt="Footer Visual" className="footer-image" />
          </div>
        </div>

        <div className="footer-bottom">
          {!isMobile && (
            <div className="footer-logo">
              <img src={edcLogoWhite} alt="EDC Squared Logo" />
            </div>
          )}
          <div className="footer-links">
            <div className="footer-column">
              <div className="footer-idividual-column">
                <h4>EDC squared</h4>
                <ul>
                  <li><Link to={`${BASE_URL}/for-creators`} className="footer-link">For Creators</Link></li>
                  <li><Link to={`${BASE_URL}/for-brands`} className="footer-link">For Brands</Link></li>
                  <li><Link to={`${BASE_URL}/our-purpose`} className="footer-link">Our Purpose</Link></li>
                  <li><Link to={`${BASE_URL}/our-news`} className="footer-link">Our Story / News</Link></li>
                </ul>
              </div>
              <div className="footer-idividual-column">
                <h4>Support</h4>
                <ul>
                  <li><Link to={`${BASE_URL}/lets-talk`} className="footer-link">Let's Connect</Link></li>
                  <li><Link to={`${BASE_URL}/privacy-policy`} className="footer-link">Privacy Policy</Link></li>
                </ul>
              </div>
              <div className="footer-idividual-column">
                <h4>Join Us</h4>
                <ul>
                  <li><a href="#login" className="footer-link" onClick={handleLoginClick}>Login or Sign up</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {isMobile && (
          <div className="footer-logo-mobile">
            <img src={edcLogoWhite} alt="EDC Squared Logo" />
          </div>
        )}

        <div className="footer-copyright">
          <p>© {currentYear} Copyright EDC Squared. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
