export enum SuccessMessages {
  Updated = 'Updated Successfully',
  Removed = 'Removed Successfully',
}

export enum ErrorMessages {
  InvalidEmail = 'Invalid Email, Please Enter A Valid Email',
  InvalidEntry = 'Invalid Entry, Please Enter A Valid Email',
  DuplicateEntry = 'Duplicate Entry, This email is already in the list.'
}
