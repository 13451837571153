import {
  CreateCreativeRequestEarningsInput,
  CreativeRequestEarnings,
  CREATIVE_REQUEST_EARNINGS_RESPONSE,
  UpdateCreativeRequestEarningsInput,
} from 'API';
import { StyledDataGrid } from 'components/DataGrid';
import { format } from 'date-fns';
import {
  UseAddCreativeEarning,
  UseGetCreativeEarningsByCreativeId,
  UseUpdateCreativeEarning,
} from 'hooks';
import { DATA_GRID_ROW_OPTIONS } from 'hooks/utils';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { withAdmin } from 'state/admin';
import { getISODate } from 'utils/utils';
import EarningsModal from './components/EarningsModal/EarningsModal';
import TableHead from './components/TableHeader/TableHeader';

export interface IEarningsTabProps {
  creativeRequestId: string;
  userProfileId: string;
}

const EarningsTab = ({
  creativeRequestId,
  userProfileId,
}: IEarningsTabProps) => {
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCreativeEarning, setSelectedCreativeEarning] =
    useState<CreativeRequestEarnings | null>(null);
  const [tableData, setTableData] = useState<
    CREATIVE_REQUEST_EARNINGS_RESPONSE[]
  >([]);
  const { addEarning, loading: addLoading } = UseAddCreativeEarning();
  const { updateEarning, loading: updateLoading } = UseUpdateCreativeEarning();
  const { data, getEarningsByCreative } = UseGetCreativeEarningsByCreativeId();

  useLayoutEffect(() => {
    if (!isInitiallyLoaded) {
      getEarningsByCreative({
        variables: { creativeRequestId },
        errorPolicy: 'ignore',
      });
      setIsInitiallyLoaded(true);
    }
  }, [creativeRequestId]);

  useEffect(() => {
    const items = (data?.getCreativeEarningsByCreative?.items ||
      []) as unknown as CREATIVE_REQUEST_EARNINGS_RESPONSE[];

    if (items) {
      const formattedItems = items.map((i, idx) => ({ ...i, id: idx }));
      setTableData(formattedItems);
    }
  }, [data]);

  const onReset = useCallback(async () => {
    setIsModalOpen(false);
    setSelectedCreativeEarning(null);
    getEarningsByCreative({ variables: { creativeRequestId } });
  }, [creativeRequestId, getEarningsByCreative]);

  const onAddCreativeEarning = async (
    data:
      | CreateCreativeRequestEarningsInput
      | UpdateCreativeRequestEarningsInput
  ) => {
    if (selectedCreativeEarning) {
      await updateEarning({
        variables: {
          input: {
            creatorId: userProfileId,
            creativeRequestEarningId:
              selectedCreativeEarning.creativeRequestEarningId,
            amount: Number(((Number(data.amount) || 0) * 0.1).toFixed(3)),
          },
        },
      });

      toast.success('Updated');
      return onReset();
    }

    const date = new Date();
    date.setMonth(data.month as unknown as number);
    date.setDate(1);
    const formattedMonth = format(date, 'yyyy-MM-dd');

    const item = {
      creativeRequestId,
      ...(data.toDate && { toDate: getISODate(data.toDate) }),
      ...(data.fromDate && { fromDate: getISODate(data.fromDate) }),
      amount: Number((Number(data.amount) * 0.1).toFixed(2)),
      month: formattedMonth,
    } as CreativeRequestEarnings;

    await addEarning({
      variables: { ...item, userProfileId },
    });

    onReset();
    toast.success('Created successfully');
  };

  const onUpdateEarning = (earning) => {
    setIsModalOpen(true);
    setSelectedCreativeEarning(earning);
  };

  const onToggleEarningModal = () => {
    setSelectedCreativeEarning(null);
    setIsModalOpen(true);
  };

  const tableHead = TableHead({
    onAddEarning: onToggleEarningModal,
    onUpdateEarning,
  });

  return (
    <div>
      <StyledDataGrid
        className="max-w-[50%]"
        rows={tableData}
        columns={tableHead}
        paginationMode="client"
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [
              { field: 'month', sort: 'desc' },
              { field: 'updatedAt', sort: 'desc' },
            ],
          },
        }}
        pageSizeOptions={DATA_GRID_ROW_OPTIONS}
        disableRowSelectionOnClick={true}
        disableColumnResize={true}
        disableAutosize={true}
        disableColumnMenu={true}
        autosizeOnMount={true}
        disableColumnSelector={true}
        autosizeOptions={{
          expand: true,
          includeHeaders: true,
          includeOutliers: true,
        }}
      />

      <EarningsModal
        onClose={onReset}
        isOpen={isModalOpen}
        onSubmit={onAddCreativeEarning}
        earning={selectedCreativeEarning}
        isLoading={addLoading || updateLoading}
      />
    </div>
  );
};
export default withAdmin(EarningsTab);
