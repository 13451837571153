import {
  ADMIN_STATUS,
  CreativeRequest,
  CREATIVE_REQUEST_LAST_UPDATED_BY,
  UserPaymentDetails,
  BRAND_STATUS,
  CREATIVE_STATUS,
} from 'API';
import AdminApprovalContent from 'components/adAdminApproval/Index';
import Modal from 'components/authentication/modal';
import Table, { Tdata } from 'components/table/Table';
import { useRequestStatusSendEmail } from 'hooks/query/useEmail';
import React, { Dispatch, FC, SetStateAction, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withAdmin } from 'state/admin';
import { AdminRoutes, AuthRoutes } from 'utils';
import { UpdateCreativeRequest } from '../../hooks';
import './adminDashboardTable.css';
import { API, graphqlOperation } from 'aws-amplify';
import { sendDetailsMessage } from 'graphql/mutations';
import { toast } from "react-toastify";
import { ErrorMessages, SuccessMessages } from "../../pages/adminCreativeApproval/components/constants";

type AdminDashboardTableProps = {
  paymentDetails: UserPaymentDetails[] | null;
  paymentLoading?: boolean;
  creativeRequestsData?: CreativeRequest[] | null;
  reqloading?: boolean;
  setIsShowBoxes: Dispatch<SetStateAction<boolean>>;
};

interface Transaction {
  paymentAmount: number; // The amount of the payment
  createdAt?: string; // Example date property (adjust if you have a date field)
}

const TRANSACTIONS_TABLE_HEADER_CELLS = [
  'transactions',
  'numberOfTransactions',
  'totalAmount',
];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const AdminDashboardTable: FC<AdminDashboardTableProps> = ({
  paymentDetails,
  paymentLoading,
  creativeRequestsData,
  reqloading,
  setIsShowBoxes,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<Tdata[]>([]);
  const [requestsTableData, setRequestsTableData] = useState<Tdata[] | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showInspiration, setShowInspiration] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCreativeRequest, setSelectedCreatveRequest] = useState<CreativeRequest | null>(null);
  const { updateRequest, loading: updateCreativeRequestLoading } = UpdateCreativeRequest();
  const { sendEmailData } = useRequestStatusSendEmail();

  const getTotalAmount = (data: Transaction[]) =>
    Number(data.reduce((acc, item) => acc + item.paymentAmount, 0).toFixed(2));

  const GetDateMonthName = (date: string) => {
    const updatedDate = date?.split('-')[0];
    const dateMonth = updatedDate?.includes('.')
      ? updatedDate?.split('.')[1]
      : (updatedDate?.split('/')[1] as string | undefined);
    const dateYear = updatedDate?.includes('.')
      ? updatedDate?.split('.')[2]
      : updatedDate?.split('/')[2];

    let monthName = '';
    if (dateMonth) monthName = MONTHS[Number(dateMonth) - 1] || '';
    return `${monthName} ${dateYear}`;
  };

  // Memoize the grouped transactions map based on paymentDetails
  const groupedTransactions = useMemo(() => {
    const GroupMap = new Map();

    if (paymentDetails?.length) {
      for (const detail of paymentDetails) {
        const { userTransactions } = detail;
        if (userTransactions?.items.length) {
          for (const transaction of userTransactions.items) {
            const day = new Date(transaction?.createdAt || '');
            let date = '';
            day.setMonth(day.getMonth() + 1);
            date = `${new Date(transaction?.createdAt || '').toLocaleString().split(',')[0]}-${day.toLocaleString().split(',')[0]}`;

            if (!GroupMap.has(date)) {
              GroupMap.set(date, [transaction]);
            } else {
              const existingTransactions = GroupMap.get(date);
              existingTransactions?.push(transaction);
            }
          }
        }
      }
    }

    // Sort the grouped transactions by date
    const sortedArray = Array.from(GroupMap).sort((a, b) => {
      return (
        new Date(b[0].split('-')[0].split('.').reverse().join('-')).getTime() -
        new Date(a[0].split('-')[0].split('.').reverse().join('-')).getTime()
      );
    });

    return new Map(sortedArray);
  }, [paymentDetails]);

  // Effect to set the table data when grouped transactions change
  useEffect(() => {
    if (groupedTransactions.size && !tableData.length) {
      const newTableData = [...groupedTransactions.entries()].map((transaction) => {
        return {
          transactions: GetDateMonthName(transaction[0]) || '',
          numberOfTransactions: transaction[1]?.length,
          totalAmount: `$${getTotalAmount(transaction[1])}`,
          transactionDate: transaction[0],
        };
      });

      setTableData(newTableData);
    }
  }, [groupedTransactions, tableData.length]);

  // Effect to set requests table data
  useEffect(() => {
    setRequestsTableData(
      (creativeRequestsData?.slice(0, 10) || []).map((request) => ({
        id: request?.id || '',
        img: request.creatorProfile?.avatar || '/images/default-image.png',
        creativeId: request.uniqueId,
        creatorName: request?.creatorProfile?.name,
        activationName: request?.brief?.BriefName,
      }))
    );
  }, [creativeRequestsData]);

  const getLoadingStatus = () => !(!paymentLoading && tableData.length);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (!url.searchParams.get('request')) {
      setIsShowBoxes(true);
      setShowInspiration(false);
      return;
    }

    setShowInspiration(true);
    setIsShowBoxes(false);
  }, [location]);


  const updateCreativeRequestStatus = async (
    newStatus: string,
    comment?: string,
    sendEmail?: boolean,
    previousStatus?: string
  ) => {
    console.log("we starting the creative request");

    if (!selectedCreativeRequest) {
      console.log("returned");
      return;
    }

    setLoading(true);

    try {
      const isApproved = newStatus === ADMIN_STATUS.Approved;
      const adminComment = [
        ...(selectedCreativeRequest.adminComment || []),
        comment || "",
      ];

      let updateBrandStatus = "";

      if (previousStatus === ADMIN_STATUS.Revision) {
        updateBrandStatus = CREATIVE_STATUS.Submitted;
      }

      // Perform the update request and handle the result with .then()
      updateRequest({
        variables: {
          input: {
            id: selectedCreativeRequest.id,
            adminApproval: ADMIN_STATUS[newStatus],
            lastUpdatedBy: CREATIVE_REQUEST_LAST_UPDATED_BY.ADMIN,
            ...(updateBrandStatus !== "" && { status: updateBrandStatus }),
            ...(!isApproved && { adminComment }),
          },
        },
        errorPolicy: "ignore",
      })
        .then(() => {
          if (sendEmail) {
            return sendEmailData({
              variables: {
                brandBriefId: selectedCreativeRequest.brandBriefId,
                creativeRequestUrl: `creatives?id=${selectedCreativeRequest.id}`,
                email: selectedCreativeRequest.creatorProfile?.email,
                name: selectedCreativeRequest?.creatorProfile?.name,
                brandBriefName: selectedCreativeRequest.BriefName,
                creativeUniqueId: selectedCreativeRequest.uniqueId,
                emailType: newStatus.toUpperCase(),
                feedback: adminComment || "",
              },
            });
          }
        })
        .then(() => {
          setLoading(false);
          setShowSuccessModal(true);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error during update or email sending:", error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleEmailAllClick = () => {
    setShowConfirmation(true); // Show the confirmation popup
  };

  const handleConfirmEmailAll = () => {
    setShowConfirmation(false); // Close the popup
    const emailList = creativeRequestsData
      ?.filter(
        (request) =>
          request.adminApproval === ADMIN_STATUS.Approved &&
          request.status === CREATIVE_STATUS.Approved
      )
      .map((request) => {
        const paymentDetail = paymentDetails?.find(
          (payment) => payment.id === request.creatorProfile?.id
        );

        if (paymentDetail?.accountNumber) {
          return null;
        }

        return request.creatorProfile?.email || null;
      })
      .filter(Boolean);

    console.log('Email List:', emailList); // Replace this with actual email-sending logic
  };

  const handleCancelEmailAll = () => {
    setShowConfirmation(false); // Close the popup
  };

  return (
    <div className="">
      {showInspiration ? (
        <div className="">
          <div onClick={(e) => e.stopPropagation()} className="z-[99]">
            <AdminApprovalContent
              videoUrl={selectedCreativeRequest?.tiktokCreativeUrl || ''}
              onClose={() => setShowInspiration(true)}
              request={selectedCreativeRequest}
              createAdPayload={{}}
              updateCreativeRequestStatus={updateCreativeRequestStatus}
              type="Admin"
              reqLoading={loading}
            />
          </div>
          <Modal
            isOpen={showSuccessModal}
            handleClose={() => {
              setIsShowBoxes(true);
              setShowInspiration(false);
              setShowSuccessModal(false);
            }}
            type="brand"
            content="The Status of the creative request was successfully changed"
          />
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-[30px]">
          <>
            <div className="md:col-span-6 col-span-12">
              <h2
                className="text-2xl font-bold underline mb-4"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Payments
              </h2>
              <Table
                data={tableData as Tdata[]}
                rows={TRANSACTIONS_TABLE_HEADER_CELLS}
                loading={getLoadingStatus()}
                onTransationClick={(date) => {
                  navigate(`/payment-window/${btoa(date.replaceAll('/', '.'))}`);
                }}
                pagination={1}
                extended={false}
                rowWidth="w-[110px]"
                textPosition="text-left"
                borderColor="#FF872F"
              />
            </div>

            <div className="md:col-span-6 col-span-12">
              <h2
                className="text-2xl font-bold underline mb-4"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Submissions
              </h2>
              <Table
                mainlyData={creativeRequestsData as Tdata[]}
                data={requestsTableData}
                rows={['creativeId', 'activationName', 'creatorName', 'details']}
                loading={reqloading || updateCreativeRequestLoading || !requestsTableData}
                pagination={1}
                extended={false}
                extendedURL={AdminRoutes.CreativeApproval}
                onRowClick={(request) => {
                  request && setSelectedCreatveRequest(request);
                  navigate(`${AuthRoutes.Dashboard}?request=true`);
                }}
                rowWidth="w-[110px]"
                borderColor="#FF872F"
                firstRowName="BRAND BRIEFS"
                textPosition="text-left"
              />
            </div>

            <div className="col-span-12">
              <h2
                className="text-2xl font-bold underline mb-4"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Incomplete Details
              </h2>
              <Table
                data={
                  creativeRequestsData
                    ?.filter(
                      (request) =>
                        request.adminApproval === ADMIN_STATUS.Approved &&
                        request.status === CREATIVE_STATUS.Approved
                    )
                    .map((request) => {
                      const paymentDetail = paymentDetails?.find(
                        (payment) => payment.id === request.creatorProfile?.id
                      );

                      if (paymentDetail?.accountNumber) {
                        return null;
                      }

                      return {
                        id: request.id,
                        creatorName: request.creatorProfile?.name || 'N/A',
                        creatorEmail: request.creatorProfile?.email || 'N/A',
                        creatorPhone: request.creatorProfile?.phoneNumber || 'N/A',
                        emailUser: (
                          <button
                            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
                            onClick={async () => {
                              try {
                                const response = await API.graphql({
                                  query: sendDetailsMessage,
                                  variables: {
                                    email: request.creatorProfile?.email,
                                  },
                                  authMode: 'AMAZON_COGNITO_USER_POOLS', // Force Cognito User Pools
                                });
                                console.log('Lambda response:', response.data.sendDetailsMessage);
                                toast.success(SuccessMessages.SendSuccess);
                              } catch (error) {
                                console.error('Error calling mutation:', error);
                                toast.error(ErrorMessages.SomethingWrong);
                              }
                            }}
                          >
                            Email
                          </button>
                        ),
                      };
                    })
                    .filter(Boolean)
                    .sort((a, b) =>
                      (a?.creatorName || '').localeCompare(b?.creatorName || '', 'en', {
                        sensitivity: 'base',
                      })
                    ) || []
                }
                rows={['creatorName', 'creatorEmail', 'creatorPhone', 'emailUser']}
                loading={reqloading || !creativeRequestsData?.length}
                extended={false}
                extendedButton={true}
                extendedOnClick={handleEmailAllClick}
                extendedName={'Email All'}
                rowWidth="w-[150px]"
                borderColor="#FF872F"
                textPosition="text-left"
              />

              {showConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white rounded-lg shadow-lg p-6 w-[400px]">
                    <h3 className="text-lg font-bold mb-4">
                      Are you sure you want to email all users with incomplete banking
                      details?
                    </h3>
                    <div className="flex justify-end gap-4">
                      <button
                        className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
                        onClick={handleCancelEmailAll}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
                        onClick={handleConfirmEmailAll}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </>
        </div>
      )}
    </div>
  );
};

export default withAdmin(AdminDashboardTable);
