import React, { useEffect, useState } from 'react';
import GradientCard from 'components/gradientCard/gradientCard';
import CreatorStatsCard from 'components/creatorStatsCard/creatorStatsCard';
import { CreatorDashboardBoxes } from 'utils';
import { UseGetCreativeEarnings } from '../../hooks';

const CreatorStatsSection: React.FC<{ creatorId: string; profileCompletionPercentage: number }> = ({
    creatorId,
    profileCompletionPercentage,
}) => {
    const [currentEarnings, setCurrentEarnings] = useState(0);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [previousMonthEarnings, setPreviousMonthEarnings] = useState(0);

    const { getEarnings, data: earningsData, loading: earningsLoading } = UseGetCreativeEarnings();

    useEffect(() => {
        getEarnings({ variables: { creatorId } });
        console.log("profileData?.id", creatorId);

    }, [creatorId, getEarnings]);

    const calculateEarnings = (earnings: any[]) =>
        earnings.reduce(
            (a, e) => ({
                lifetime: a.lifetime + e.lifetimeEarnings,
                current: a.current + e.currentEarnings,
                previous: a?.previous + e.previousEarnings || 0,
            }),
            {
                lifetime: 0,
                current: 0,
                previous: 0,
            }
        );

    useEffect(() => {
        if (!earningsData) {
            return;
        }

        const calculated = calculateEarnings(earningsData);
        setCurrentEarnings(calculated.current);
        setTotalEarnings(calculated.lifetime);
        setPreviousMonthEarnings(calculated.previous);
    }, [earningsData]);

    return (
        <div className="lg:grid flex grid-cols-4 gap-[30px] w-full lg:overflow-x-visible overflow-x-auto overflow-y-hidden creator-dashboard p-0 lg:pb-0 pb-[4px] mb-8">
            <GradientCard>
                <CreatorStatsCard
                    type={CreatorDashboardBoxes.Profile}
                    value={`${profileCompletionPercentage || 0}%`}
                />
            </GradientCard>

            <GradientCard>
                <CreatorStatsCard
                    type={CreatorDashboardBoxes.Earnings}
                    value={`$${currentEarnings.toFixed(2)}`}
                />
            </GradientCard>

            <GradientCard>
                <CreatorStatsCard
                    type={CreatorDashboardBoxes.PreviousMonthEarnings}
                    value={`$${previousMonthEarnings.toFixed(2)}`}
                />
            </GradientCard>

            <GradientCard>
                <CreatorStatsCard
                    type={CreatorDashboardBoxes.ClickThrough}
                    value={`$${totalEarnings.toFixed(2)}`}
                />
            </GradientCard>
        </div>
    );
};

export default CreatorStatsSection;
