import { FC } from 'react';

const TootlTipContent: FC = (): JSX.Element => {
  return (
    <div>
      <h3 className="head-text font-[900]">OTP Has Been Sent</h3>
      <p className="head-text">Please Check Your Email</p>
    </div>
  );
};

export default TootlTipContent;
